import React from "react";
import { useNavigate } from "react-router-dom";
import mianLogo from "./imageCommon/mainLogo.png";

const Header = () => {
  const navigate = useNavigate();
  const handleMenuClick = (url) => {
    navigate(url);
  };

  return (
    <>
      <div id="header">
        <div className="header_in">
          {/* 로고 */}
          <h1>
            <a href="/"></a>
          </h1>
          {/* 전체메뉴 피시화면 */}
          <a href="#" className="btnMenu_m"></a>
          <div className="langBtn">
            <a href="http://dynasystem.co.kr:8090/" target="_blank" className="">
              INTRANET
            </a>
          </div>
        </div>

        {/* 메인 메뉴 */}
        <div id="gnb">
          <div id="menu">
            <ul className="menu">
              <li>
                <a href="/about">회사소개</a>
                <div className="menu_depth">
                  <ul>
                    <li>
                      <a href="/about">
                        회사개요
                      </a>
                    </li>
                    <li>
                      <a href="/ceo">
                        CEO 인사말
                      </a>
                    </li>
                    <li>
                      <a href="/philosophy">
                        기업이념
                      </a>
                    </li>
                    <li>
                      <a href="/history">
                        회사연혁
                      </a>
                    </li>
                    <li>
                      <a href="/organization">
                        회사조직
                      </a>
                    </li>
                    <li>
                      <a href="/location">
                        오시는길
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <a href="/field">사업소개</a>
                <div className="menu_depth">
                  <ul>
                    <li>
                      <a href="/field">
                        사업분야
                      </a>
                    </li>
                    <li>
                      <a href="/area">
                        사업영역
                      </a>
                    </li>
                    <li>
                      <a href="/partner">
                        고객사/협력사
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <a href="/groupWare">솔루션</a>
                <div className="menu_depth">
                  <ul>
                    <li>
                      <a href="/groupWare">
                        그룹웨어
                      </a>
                    </li>
                    <li>
                      <a href="/contents">
                        콘텐츠관리 시스템
                      </a>
                    </li>
                    <li>
                      <a href="/education">
                        교육관리 시스템
                      </a>
                    </li>
                    <li>
                      <a href="distribution">
                        물류관리 시스템
                      </a>
                    </li>
                    <li>
                      <a href="blockchain" >
                        의료 블록체인
                      </a>
                    </li>
                    {/* <li>
                      <a href="#" onClick={() => {}}>
                        MSA
                      </a>
                    </li> */}
                  </ul>
                </div>
              </li>

              <li>
                <a href="/recruit">
                  채용정보
                </a>
                <div className="menu_depth">
                  <ul>
                    <li>
                      <a href="/recruit?param=1">
                        인재상
                      </a>
                    </li>
                    <li>
                      <a href="/recruit?param=2">
                        채용절차
                      </a>
                    </li>
                    <li>
                      <a href="/recruit?param=3">
                        인사제도
                      </a>
                    </li>
                    <li>
                      <a href="/recruit?param=4">
                        복리후생
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a href="mailto:mailto:kh.yim@dynasystem.co.kr" target="_blank">고객지원</a>
                <div className="menu_depth">
                  <ul>
                    <li>
                      <a href="mailto:mailto:kh.yim@dynasystem.co.kr" target="_blank">이메일 문의</a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div id="menuArea">
        <div className="menuList">
          <h1>
            <a href="./" className="menu_logo">
              <img src={mianLogo} alt="logo" />
            </a>
          </h1>
          <ul className="list">
            <li className="icon01">
              <a className="dropdown-toggle" href="#none" id="menu_close">
                회사소개
              </a>
              <ul className="sMenu">
                <li>
                  <a href="">회사개요</a>
                </li>
                <li>
                  <a href="">CEO 인사말</a>
                </li>
                <li>
                  <a href="">기업이념</a>
                </li>
                <li>
                  <a href="">회사연혁</a>
                </li>
                <li>
                  <a href="">회사조직</a>
                </li>
                <li>
                  <a href="">오시는길</a>
                </li>
              </ul>
            </li>

            <li className="icon01">
              <a className="dropdown-toggle" href="#none" id="menu_close">
                사업소개
              </a>
              <ul className="sMenu">
                <li>
                  <a href="">사업분야</a>
                </li>
                <li>
                  <a href="">사업영역</a>
                </li>
                <li>
                  <a href="">고객사/협력사</a>
                </li>
              </ul>
            </li>

            <li className="icon01">
              <a className="dropdown-toggle" href="#none" id="menu_close">
                솔루션
              </a>
              <ul className="sMenu">
                <li>
                  <a href="">그룹웨어</a>
                </li>
                <li>
                  <a href="">콘텐츠관리 시스템</a>
                </li>
                <li>
                  <a href="">교육관리 시스템</a>
                </li>
                <li>
                  <a href="">물류관리 시스템</a>
                </li>
                <li>
                  <a href="">통합검색엔진</a>
                </li>
                <li>
                  <a href="">MSA</a>
                </li>
              </ul>
            </li>

            <li className="icon01">
              <a className="dropdown-toggle" href="#none" id="menu_close">
                채용정보
              </a>
              <ul className="sMenu">
                <li>
                  <a href="">인재상</a>
                </li>
                <li>
                  <a href="">채용안내</a>
                </li>
                <li>
                  <a href="">복리후생</a>
                </li>
              </ul>
            </li>

            <li className="icon01">
              <a className="dropdown-toggle" href="#none" id="menu_close">
                고객지원
              </a>
              <ul className="sMenu">
                <ul>
                  <li>
                    <li>
                      <a href="mailto:kh.yim@dynasystem.co.kr">이메일 문의</a>
                    </li>
                  </li>
                </ul>
              </ul>
            </li>
          </ul>
          <button type="button" className="btnMenu_mClose" title="close">
            Close
          </button>
        </div>
      </div>
    </>
  );
};

export default Header;
